<template>
<div>
    <v-container>
        <v-layout row wrap>
            <h1>Bienvenido al panel de administración (SOLICITANTE)</h1>
        </v-layout>
    </v-container>
    <v-container grid-list-md text-xs-center class="pa-3  white">
        <v-layout>
            <v-flex xs6 row justify-center align-center d-flex>
                <v-img
                   src="/images/actividad/comienza.png"
                   width="50px"
                    ></v-img>
                <div text-xs-left>
                    <h3 class="text-uppercase">{{ `${this.$store.state.user.nombre} ${this.$store.state.user.app_pat}`}}</h3>
                    <h3 class="text-uppercase">Membresía comienza</h3>
                </div>
            </v-flex>
            <v-flex xs6 >
                <v-container fluid class="fondogris" >
                    <v-layout  row>
                        <v-flex d-flex xs12 md12 lg12  sm3 >
                            <h5 class="text-uppercase">Nivel membresía</h5>
                        </v-flex>
                    </v-layout>
                    <v-layout  row>
                        <v-flex xs4 md4 lg4d-flex  sm3 class="centrar" align-center justify-center  >
                            <v-img
                            src="/images/actividad/comienza.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa1 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcomienza">comienza</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                            src="/images/actividad/crece.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa2 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcrece ">crece</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                            src="/images/actividad/expande.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa3 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorexpande  ">expande</h5>
                            </div>
                        </v-flex>
                    </v-layout>
               </v-container>
               <v-progress-linear
                        color="progress1"
                        height="25"
                        value="75"
                        class="progressradius"
                        >6 puntos</v-progress-linear>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs4 offset-xs4 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>SABER MÁS</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout class="pa-3"  d-flex justify-center align-center>
            <v-flex xs2  d-flex justify-center align-center>
                 <v-img src="/images/actividad/vermas.png"  width="50px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12 text-xs-left>
              <p> 
                  <h4> Estimados,</h4>
                    <p> 
                        <b>
                          Para RedCapital es muy importante poder ayudar y ser un real apoyo para nuestros clientes. Por esta razón, cada semana durante un mes, compartiremos con ustedes material de educación financiera, que los ayudará a hacer crecer sus negocios. Al finalizar la lectura de los documentos encontrarán algunas actividades sencillas relacionadas con el tema tratado. Al completar las actividades se irán sumando puntos los que se traducirán en diferentes niveles de membresía y, por tanto, de beneficios con los cuales contarán.
                        </b>
                    </p>
                    <p> 
                       Queremos contarte un poco más acerca de las membresías; en qué consisten y cuales son los beneficios a los cuales podrás acceder.
                    </p>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs4 offset-xs4 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>ACERCA DEL BLOG</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex class="text-xs-left">
                <p>RedCapital entregará material de educación financiera mediante entradas de su blog, las cuales serán compartidas con los usuarios mediante un mail semanal. Inicialmente serán 4 grandes temas 1.Organiza tus finanzas, 2.Controla tus costos, 3.Planifica tu futuro y 4.Gestiona tu deuda.
                    </p>
            </v-flex>
        </v-layout>
        <EntradasBlog />
        <v-layout>
            <v-flex xs6 offset-xs3 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>ACERCA DE LOS DESAFÍOS/ACTIVIDADES</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex class="text-xs-left">
                    <p>En cada entrada del blog mencionado anteriormente, encontrarás al final de la lectura una sección con diferentes actividades relacionadas al tema sobre el cual acabas de leer. La idea es que puedas ir realizándolas, ya sea todas de una sola vez o al ritmo que tu elijas. Por cada actividad realizada irás sumando puntos.
                    </p>
            </v-flex>
        </v-layout>
        <Actividades />
        <v-layout>
            <v-flex xs6 offset-xs3 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>ACERCA DE LOS PUNTOS</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex class="text-xs-left">
                    <p>Los puntos se obtienen a medida que el usuario va completando los distintos desafíos que puede encontrar en cada una de las cuatro temáticas de nuestro blog.<br>
                    Cada actividad otorga 1 punto al ser completada por el usuario. los puntos son independientes de la temática, es decir, se suman por igual no importando de cuál tema sea la o las actividades realizadas.<br>
                    Al ir sumando puntos, estos te servirán para avanzar en tu nivel de membresía y así poder obtener mejores beneficios.
                    </p>
            </v-flex>
        </v-layout>
        <Puntos />
        <v-layout>
            <v-flex xs6 offset-xs3 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>ACERCA DE LAS MEMBRESÍAS</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex class="text-xs-left">
                   <p> Las membresías constan de tres niveles: nivel 1. “Comienza”, luego nivel 2. “Crece” y finalmente nivel 3. “Expande”.
                    Cada nivel está determinado por un rango específico de puntaje, siendo los siguientes: de 0 a 9 puntos corresponde al primer nivel, luego de 9 a 12 puntos el segundo nivel y de 12 a 15 puntos el nivel final. 
                    Cada nivel de membresía permite al usuario acceder a ciertos beneficios específicos, los cuales van mejorando a medida que se va subiendo de nivel.</p>
            </v-flex>
        </v-layout>
        <h1 class="pa-3">MEMBRESÍAS</h1>
  <v-layout class="pt-3 pb-3">
                <v-flex xs4 class="pa-2">
                    <v-card class="pa-2 centrar circleetapamembresia1 fondogrismembresia">
                        <v-img
                            src="/images/actividad/comienza.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia1">COMIENZA</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia1"
                                        height="25"
                                        value="40"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px">0 a 9 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom1">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Comienza</h5></li>
                                    <li><h5>Recordatorios financieros personalizados</h5></li>
                                    <li><h5>Material con tips para el negocio según nivel</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs4 class="pa-2">
                      <v-card class="pa-2 centrar  circleetapamembresia2 fondogrismembresia">
                        <v-img
                            src="/images/actividad/crece.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia2">CRECE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia2"
                                        height="25"
                                        value="80"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 20px 2px 0px;text-align:center">9 a 12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom2">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Crece</h5></li>
                                     <li><h5>Recordatorios financieros personalizados</h5></li>
                                      <li><h5>Material con tips para el negocio según nivel</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs4 class="pa-2">
                     <v-card class="pa-2 centrar  circleetapamembresia3 fondogrismembresia">
                        <v-img
                            src="/images/actividad/expande.png"
                            width="80%"
                            ></v-img>
                            <v-card-title class="pa-1">
                                <h3 class="colormembresia3">EXPANDE</h3>
                            </v-card-title>
                            <v-container class="pa-1 pl-3 pr-3">
                                <v-layout>
                                    <v-flex>
                                        <v-progress-linear
                                        color="progressmembresia3"
                                        height="25"
                                        value="100"
                                        class="progressradius"
                                        ><div style="text-align: left;padding: 2px 10px;text-align:center">12 Pts</div>
                                        </v-progress-linear>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                           <v-card-title class="pa-1">
                                <h3 class="boredermembresiasbotom3">BENEFICIOS</h3>
                            </v-card-title>
                            <v-card-title class="pa-1" style="text-align:left">
                                <ul>
                                    <li><h5>Chat WhatsApp grupal nivel Expande</h5></li>
                                    <li><h5>Recordatorios financieros personalizados</h5></li>
                                    <li><h5>Material con tips para el negocio según nivel</h5></li>
                                    <li><h5>Certificado nivel más alto</h5></li>
                                </ul>
                            </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
    <v-layout>
        <v-flex xs12 class="">
            <v-card class="fondogrismembresia positionrelative pt-2">
                <v-img
                    src="/images/actividad/gold.png"
                    width="30px"
                    class=" positionabsolutegold"></v-img>
                <div class="pa-3 centrar">
                    <v-img
                        src="/images/actividad/beneficiogold.png"
                        width="120px"></v-img>
                </div>
                <v-card-title class="text-xs-center justify-content-center pa-2" >
                    <h3 class="colorgold text-xs-center "> <span class=" font-weight-black font-italic"> BENEFICIOS GOLD</span></h3>
                </v-card-title>
                <v-card-content>
                    <p>Estos beneficios están enfocados en ofrecerte menores tasas y plazos mayores a medida que cumplas con algunos requisitos referentes a tu nivel 
                        de ventas, a haber respondido la encuesta de perfil financiero, haber completado niveles específicos de membresía y tareas.</p>
                </v-card-content>
                <v-card-title class="text-xs-center justify-content-center pa-2" >
                    <h3 class=" text-xs-center bordergold "> <span class=" font-weight-black font-italic"> TABLA DE ESPECIFICACIONES BENEFICIOS</span></h3>
                </v-card-title>
                
                <table class="tables pa-1" cellspacing="0">
                    <thead>
                        <th style="background: transparent;"></th>
                        <th>
                            <span class="colorblanco font-weight-light">   REQUISITOS</span>
                        </th>
                        <th>
                            <span class="colorblanco font-weight-light"> MONTO MÁXIMO</span>
                        </th>
                        <th>
                            <span class="colorblanco font-weight-light">TASA MÁXIMA</span>
                        </th>
                        <th>
                            <span class="colorblanco font-weight-light"> PLAZO MÁXIMO</span>
                        </th>
                    </thead>
                    <tbody>
                        <tr class="bordertable1">
                            <td style="border-radius: 8px 8px 0px 0px;" class="bordertable1">
                                    <span class="colorblanco font-weight-light">GOLD 1</span>
                            </td>
                            <td class="bordertable2">
                                <ul>
                                    <li><h5>Ventas Formales sobre MM$2 mensuales</h5></li>
                                    <li><h5>Filtro de Riego</h5></li>
                                    <li><h5>Encuesta sensata</h5></li>
                                </ul>
                            </td>
                            <td class="bordertable2">
                                <b>$1.000.000</b><br>
                                <b> Cuota $217.399</b>
                            </td>
                            <td class="bordertable2">
                                <b>2,9%</b>
                            </td>
                            <td class="bordertable2">
                                <b>5 Meses</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="bordertable1">
                                <span class="colorblanco font-weight-light"> GOLD 2</span>
                            </td>
                            <td class="bordertable2">
                                <ul>
                                    <li><h5>Ventas Formales sobre MM$3 mensuales</h5></li>
                                    <li><h5>Filtro de Riego</h5></li>
                                    <li><h5>Buen comportamiento de pago en 1° Crédito</h5></li>
                                    <li><h5>Encuesta sensata</h5></li>
                                    <li><h5>Terminado nivel “Comienza”</h5></li>
                                </ul>
                            </td>
                            <td class="bordertable2">
                                <b>$1.500.000</b><br>
                                <b>Cuota $325.023</b><br>
                                <b>A 5 meses</b>
                            </td>
                            <td class="bordertable2">
                                <b>2,7%</b>
                            </td>
                            <td class="bordertable2">
                                <b>6 Meses</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="bordertable1">
                                <span class="colorblanco font-weight-light">GOLD 3</span>
                            </td>
                            <td class="bordertable2">
                                <ul>
                                    <li><h5>Ventas Formales sobre MM$5 mensuales</h5></li>
                                    <li><h5>Filtro de Riego</h5></li>
                                    <li><h5>Buen comportamiento de pago en créditos anteriores</h5></li>
                                    <li><h5>Encuesta sensata</h5></li>
                                    <li><h5>Terminado nivel “Crece”</h5></li>
                                </ul>
                            </td>
                            <td class="bordertable2">
                                    <b>$2.500.000</b><br>
                                    <b>Cuota $540.270</b><br>
                                    <b>A 5 meses (10,8%)</b>
                            </td>
                            <td class="bordertable2">
                                <b>2,5%</b>
                            </td>
                            <td class="bordertable2">
                                <b>6 Meses</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="bordertable1">
                                <span class="colorblanco font-weight-light"> GOLD 4 </span>
                            </td>
                            <td class="bordertable2">
                                <ul>
                                    <li><h5>Ventas Formales sobre MM$7 mensuales</h5></li>
                                    <li><h5>Filtro de Riego</h5></li>
                                    <li><h5>Buen comportamiento de pago en créditos anteriores</h5></li>
                                    <li><h5>Encuesta sensata</h5></li>
                                    <li><h5>Terminado nivel “Expande” y tener diploma</h5></li>
                                </ul>
                            </td>
                            <td class="bordertable2">
                                <b>$3.500.000</b><br>
                                <b>Cuota $755.516</b><br>
                                <b>A 5 meses (10,8%)</b>
                            </td>
                            <td class="bordertable2">
                                <b>2,3%</b>
                            </td>
                            <td class="bordertable2">
                                <b>12 Meses</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="border-radius: 0px 0px 8px 8px ;">
                                <span class="colorblanco font-weight-light"> GOLD 5</span>
                            </td>
                            <td>
                                <ul>
                                    <li><h5>Ventas Formales sobre MM$10  mensuales</h5></li>
                                    <li><h5>Filtro de Riego</h5></li>
                                    <li><h5>Buen comportamiento de pago en créditos anteriores</h5></li>
                                    <li><h5>Encuesta sensata</h5></li>
                                </ul>
                            </td>
                            <td>
                                <b>$5.000.000</b><br>
                                <b>Cuota $1.078.387</b><br>
                                <b>A 5 meses (10,8%)</b>
                            </td>
                            <td>
                                <b>2,1%</b>
                            </td>
                            <td>
                                <b>12 Meses</b>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                <div class="pa-3" style="color:#7D694F">
                <p style="font-size: small;">*Para acceder a estos beneficios debes cumplir con los requisitos especificados en la columna izquierda de la tabla, 
                    cuando tengas disponible la opción de canjear estos beneficios gold, 
                    tu solicitud será cursada y entrará en proceso de evaluación por nuestros ejecutivos. 
                    Una ves comprobado que cumples con los requisitos, serás contactado y te entregaremos el beneficio.</p>
                </div>
            </v-card>
        </v-flex>
    </v-layout>
        <v-layout>
            <v-flex xs12>
                <v-btn @click="godashboard()" class="btnvolver">
                    <h3>VOLVER</h3>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
    
</div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import EntradasBlog from "./entradasblog.vue"
import Actividades from "./Actividades.vue"
import Puntos from "./Puntos.vue"
@Component({
    data() {
      return {
            e1: 1,
        }
    },methods:{
        godashboard(){
           /* this.$router.push({
                path: "/actividad/administracion"
            })*/
            this.$router.go(-1)
        }
    },components :{
        EntradasBlog,
        Actividades,
        Puntos
    }
})
export default class Sabermas extends Vue {}
</script>

<style scoped >
.boredermembresiasbotom1 {
    border-bottom: 2px solid #14BC9B;
}
.boredermembresiasbotom2 {
    border-bottom: 2px solid #BE75F7;
}
.boredermembresiasbotom3 {
    border-bottom: 2px solid #FCAF3C;
}
.progressmembresia1 {
    background: #14BC9B !important;
}
.progressmembresia2 {
    background: #BE75F7;
}
.progressmembresia3 {
    background: #FCAF3C;
}
.fondogrismembresia{
    background: #F4F4F4 !important;
}
.circleetapamembresia1, .circleetapamembresia2, .circleetapamembresia3 {
    position: relative;
}
.circleetapamembresia1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
}
.circleetapamembresia2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
}
.circleetapamembresia3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
}
.colormembresia1 {
    color: #24AE94 !important;
}
.colormembresia2 {
    color: #BE75F7 !important;
}
.colormembresia3 {
    color: #E0802C !important;
}

.btnvolver{
    background-color: #6DC9F7 !important;
    color: #fff !important;
    padding: 10px 15px;
}
.fullwidth{
    width: 100%;
}
.headline {
    border-bottom: 1px solid #fff !important;
    padding-bottom: 5px;
}
.positionabsolute{
    position: absolute;
    top: 0;
    right: 0;
}
.positionrelative{
    position: relative;
}
.progressradius{
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}
.text-center{
    text-align: center;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.circleetapa1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress1{
     background: #B8EAE0;
}
.circleetapa2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress2{
     background: #BE75F7;
}
.circleetapa3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress3{
     background: #FCAF22;
}
.circleetapa1, .circleetapa2, .circleetapa3 {
    position: relative;
}
.circleetapa1 h3 {
    color: #24AE94;
}
.circleetapa2 h3 {
    color: #BE75F7;
}
.circleetapa3 h3 {
    color: #FCAF22;
}
.colornegro{
    color: #000 !important;
}
.colorblanco{
    color: #fff;
}
.colorcomienza{
    color: #24AE94;
}
.colorcrece{
    color: #BE75F7;
}
.colorexpande{
    color:#FCAF22;
}
.fondogris{
    background: #F4F4F4;
    border-radius: 10px;
}
.fondodisabled{
    background-color: #B8F6EA !important;
}
.fondopuntos{
    background: #BAD8D2;
    width: 100%;
}
.btn-color{
    background:#92AFAF !important;
}
.btn-colorpendientes{
      background:#D36523 !important;
}
.fondopendientes{
    background: #14E3BB !important;
}
.fondopuntospendientes{
    background:#1C7E6B;
     width: 100%;
}
.colorbeneficios{
    color: #3EAFD2;
}
.colorbeneficiosdisabled{
    color: #CCC7C7;
}
li::before {
    content: "-";
    padding-right: 8px;
}
li {
    padding-left: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.positionrelative{
    position: relative;
}
.positionabsolutegold{
    position: absolute;
    top: -15px;
    margin: auto;
    left: calc(50% - 15px);
}
.colorgold{
    color: #CA9E67;
}
.bordergold{
    border-bottom: 2px solid #CA9E67;
}
.positionabsolutegold{
    position: absolute;
    top: -15px;
    margin: auto;
    left: calc(50% - 15px);
}
.colorgold{
    color: #CA9E67;
}
.bordergold{
    border-bottom: 2px solid #CA9E67;
}
.tables{
    width: 100%;
}
.tables > thead > th{
    background-color: #DAB670;
    border-radius: 8px 8px 0px 0px;
}
.tables > tbody > tr > td:nth-child(1){
    background-color:#A68965;
    /*transform: rotate(270deg);*/
    padding: 0px;
}
.tables > tbody > tr > td > span{
    transform: rotate(270deg);
    display: block;
}

td{
    padding: 5px;
    background:#fff;
    border-collapse: separate;
    border-width: initial;
    border-left-style: unset;
    border-right-style: inset;
    text-align: center;
}
th{
    padding: 10px;
    border-collapse: separate;
    border-width: initial;
    border-left-style: unset;
    border-right-style: inset;
}
tr{
    border-bottom:2px solid #DAB670;
}
.bordertable1{
    border-bottom: 2px solid #F8E9CA;
}
.bordertable2{
    border-bottom: 2px solid #DAB670;
}


.positionrelative{
    position: relative;
}
.progressradius{
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}

.text-center{
    text-align: center;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.colorblanco {
    color: #fff;
}
</style>